.App {
  /* text-align: center; */
}


/* LAYOUT */

main {
  padding-top: calc(10vh + 30px);
  padding-block: calc(10vh + 30px)
}

#stretchVertical90vh {
  min-height: 95vh;
  box-sizing: border-box;
}

.allPropsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.oneProp {
  flex: 1 1 300px;
}
.seeThrough {
    background-color: rgb(250,250,250,80%) !important;
}

/* .navbar { STYLES IN THE COMPONENT
  padding-bottom: .5em;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #eeeeee !important;
  color: black !important;
  box-sizing: border-box !important;
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1 !important;
  opacity: .9 !important;
  max-width: 100% !important;
} */

.flexContainerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.flexContainerColumn {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: start;
  gap: 15px;
  padding: 1em 0;
}
.flexContainerColumn input, .flexContainerColumn textarea {
  min-width: 100%;
}

.flexContainerRow p {
  margin: 0;
}

.flexContainerRowWithWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

/* .flexContainerRowWithWrap span {
  flex: 1 1 auto;
} */

.flexContainerRowWithWrap span input{
  margin-right: 5px;
  text-align: left;
}

.flexibleGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  gap:1em;
/*   align-items: center;
  justify-items: center; */
}

.topSpacer {
  margin-top: 1em;
}



/* ELEMENTS */

textarea.uk-textarea {
  margin: 1em 0;
}

hr {
  margin: 30px;
}

i.highlighted {
  color: #F01188;
}

span.emoji {
  vertical-align: text-bottom;
}

.socialsharebuttons {
  display: flex;
}
.socialsharebuttons > * {
  margin-right: .5em;
}
.uk-radio { 
  min-width: 16px;
}

.badge {
  width: 10em;
  border-radius: 5px;
  margin-right: 1em;
}

.listNoBullets {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

button:hover, a:hover, .iconWrapper:hover {
  background-color: #F01188 !important;
  color:  white !important;
}

a:link {
  color: inherit;
  text-decoration: underline;
}

section.propsSearch {
  position: sticky;
  top: calc(0px + 3.5rem);
  z-index: 2;
}

/* Restyled */

.readableH2 {
  font-size: 1.2em;
}

.fa-regular, .fa-solid {
  font-size: 20px;
}

/* navbar */

.navbar button {
  line-height: 24px;
  padding: 0 .5em;
}
#logo_icon {
  height: 3.5rem;
  max-width: fit-content;
}
#sign_in_email_input {
  margin-right: .5em;
  width: auto;
  height: 24px;
  box-sizing: border-box;
}

.navbar a:not([href$="/"]) {
  padding: .5em;
}

.navbar i, .navbar a img {
  vertical-align: text-bottom;
}


/* new report form */
form .shortInput, form select.uk-select {
  width: 5em;
}

.flexContainerRow > select.uk-select {
  width: fit-content;
}

.spinnerContainer {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ratingStars img {
  margin-right: 1em;
  width: 1.5em;
}
/* allreports */
.propReview {
  max-height: 200px;
  overflow: hidden;
}


/* Media query for smaller screens */
@media screen and (max-width:700px){
  nav.uk-container.navbar {
    position: static !important;
  }
  main {
    padding-top: calc(30px);
    padding-block: calc(30px);
  }
  section.propsSearch {
    position: sticky;
    top: 0;
    z-index: 2;
  }

}

@media screen and (max-width: 450px){
  .flexContainerRow {
    flex-direction: column;
  }
  #sign_in_email_input {
    width: 80% !important;
  }
  nav .flexContainerRow {
    flex-direction: row;
  }
  .MuiBox-root .flexContainerRow:last-child{
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .MuiBox-root .flexContainerRow:last-child .uk-button:last-child{
    margin-bottom: 1em;
  }
    footer > div {
    grid-template-columns: 1fr !important;
  }
}