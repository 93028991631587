footer div {
	line-height: .8em;
}
footer a:link {
  padding: .5em;
  position: relative;
  left: -.5em;
}
footer a:visited {
  color: inherit;
  padding: .5em;
  position: relative;
  left: -.5em;
}

footer a.inlineLink {
  padding: 0;
  position: static;
}